<template>
 <v-container id="blog" class="text-center d-flex justify-center align-center ma-auto" style="height: fit-content" fluid>
    <v-row class="main pa-0 ma-0">
      <v-col
        class="ma-auto" style="z-index: 1"
        v-for="(item,n) in items"
        :key="n"
        cols="12"
        :sm="items.length<3?12/items.length:'10'"
        :md="items.length<5?12/items.length:'4'"
        :lg="items.length<6?12/items.length:'3'"
      >
        <v-card class="ma-auto elevation-0">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-img
                :src="item.image"
                :alt="item.alt"
                aspect-ratio="1"
                class="route-card"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <h1 class="text-h4 custom-secondary--text text-uppercase" style="-webkit-text-stroke: 0.2px white;">{{$t(item.name)}}</h1>
                </v-row>
                <v-fade-transition>
                  <v-overlay style="cursor:pointer"
                    v-if="hover"
                    @click="$navigateToPath(item.route)"
                    absolute
                    color="custom-accent"
                  >
                    <v-icon large>mdi-open-in-app</v-icon>
                  </v-overlay>
                </v-fade-transition>
              </v-img>
            </template>
          </v-hover>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Blog",

  data() {
    return {
      items: [
        { id:1, name:'gallery', route:'/blog/gallery', image: require("../../assets/images/gallery/gallery-bg.jpg"), alt:'' },
        { id:2, name:'newsevents', route:'/blog/news-events', image: require("../../assets/images/newsevents/news-events-bg.jpg"), alt:'' },
      ],
    };
  },
  components: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>
<style scoped>
@import url("../../assets/styles/blog.css");
</style>